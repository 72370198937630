exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-categories-jsx": () => import("./../../../src/pages/categories.jsx" /* webpackChunkName: "component---src-pages-categories-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */),
  "component---src-pages-support-us-jsx": () => import("./../../../src/pages/support-us.jsx" /* webpackChunkName: "component---src-pages-support-us-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/category-template.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-markdown-guide-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/opt/build/repo/content/markdown-guide/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-markdown-guide-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-mind-3-steps-to-improve-creativity-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/opt/build/repo/content/mind/3-steps-to-improve-creativity/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-mind-3-steps-to-improve-creativity-post-mdx" */),
  "component---src-templates-post-template-jsx-content-file-path-content-template-article-template-post-mdx": () => import("./../../../src/templates/post-template.jsx?__contentFilePath=/opt/build/repo/content/template/article-template/post.mdx" /* webpackChunkName: "component---src-templates-post-template-jsx-content-file-path-content-template-article-template-post-mdx" */)
}

